import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useClientFeeOverridesQuery, useDeleteClientFeeOverrideMutation } from '@backed-fi/graphql';
import { AssetIcon } from '@backed-fi/shared/components/compound/AssetIcon';
import { BlockchainNetworkLabel } from '@backed-fi/shared/components/compound/BlockchainNetworkLabel';
import { CentsFormatter } from '@backed-fi/shared';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { ClientFeesConfigurationDialog } from '@backed-fi/admin/src/app/domain/Clients/pages/Fees/components/ClientFeesConfigurationDialog';
import { useToggle } from '@backed-fi/hooks';

// region Query

const Query = gql`
  query clientFeeOverrides($clientId: String!) {
    feeConfigurations(
      where: {
        clientId: $clientId
      }
    ) {
      nodes {
        id

        issuanceInitialFee
        issuanceMinimumFee
        issuancePercentageFee

        redemptionInitialFee
        redemptionMinimumFee
        redemptionPercentageFee

        tokenDeployment {
          id
          network

          token {
            name
            symbol
          }
        }
      }
    }
  }

  mutation deleteClientFeeOverride($input: DeleteFeeConfigurationInput!) {
    deleteFeeConfiguration(input: $input)
  }
`;

// endregion

// region Helper Components

const Fees: React.FC<{ row: any, property: string }> = ({ row, property }) => (
  <Box
    sx={{
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-around'
    }}
  >
    <Typography>
      Initial: {CentsFormatter.format(row[`${property}InitialFee`])}
    </Typography>

    <Typography>
      Minimum: {CentsFormatter.format(row[`${property}MinimumFee`])}
    </Typography>

    <Typography>
      Percentage: {Number(row[`${property}PercentageFee`]) * 100}%
    </Typography>
  </Box>
);

// endregion

export const ClientFeesConfiguration: React.FC = () => {
  const params = useParams<{ id: string }>();
  const snackbar = useSnackbar();

  const feeCustomisationToggle = useToggle();

  // region Networking

  const { data } = useClientFeeOverridesQuery({
    variables: {
      clientId: params.id!
    }
  });

  const [deleteFeeMutation, { loading: isDeletingFeeConfiguration }] = useDeleteClientFeeOverrideMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'clientFeeOverrides'
    ]
  });

  // endregion

  // region Actions

  const onResetToDefault = (feeConfigurationId: string) => async () => {
    await deleteFeeMutation({
      variables: {
        input: {
          feeConfigurationId
        }
      }
    });

    snackbar.enqueueSnackbar('Fee configuration successuly reseted to the default one for that asset', {
      variant: 'success'
    });
  };

  // endregion

  return (
    <Box>
      {/* region Fee Customisation Dialog */}

      <ClientFeesConfigurationDialog
        {...feeCustomisationToggle}
      />

      {/* endregion */}

      <Box
        sx={{
          mb: 2,
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography variant='titleSmall'>
            Custom Fee Configuration
          </Typography>

          <Typography variant='subtitleSmall'>
            Fee overrides that are created specifically for this client
          </Typography>
        </Box>

        <Box>
          <Button onClick={feeCustomisationToggle.setTrue}>
            Customise Fees
          </Button>
        </Box>
      </Box>

      <DataGrid
        autoHeight
        rowHeight={100}
        rows={data?.feeConfigurations?.nodes ?? []}
        columns={[
          {
            flex: 5,
            field: 'tokenDeployment',
            headerName: 'Token',
            renderCell: ({ row }) => (
              <AssetIcon
                name={row.tokenDeployment?.token.name}
                symbol={row.tokenDeployment?.token.symbol!}
              />
            )
          }, {
            flex: 3,
            field: 'tokenDeploymentNetwork',
            headerName: 'Network',
            renderCell: ({ row }) => (
              <BlockchainNetworkLabel
                network={row.tokenDeployment?.network!}
              />
            )
          }, {
            flex: 4,
            field: 'issuanceFees',
            headerName: 'Issuance Fees',
            renderCell: ({ row }) => (
              <Fees
                row={row}
                property='issuance'
              />
            )
          }, {
            flex: 4,
            field: 'redemptionFees',
            headerName: 'Redemption Fees',
            renderCell: ({ row }) => (
              <Fees
                row={row}
                property='redemption'
              />
            )
          }, {
            flex: 2,
            field: 'id',
            headerName: '',
            renderCell: ({ row }) => (
              <Box>
                <LoadingButton
                  onClick={onResetToDefault(row?.id)}
                  loading={isDeletingFeeConfiguration}
                >
                  Reset to default
                </LoadingButton>
              </Box>
            )
          }
        ]}
      />
    </Box>
  );
};
