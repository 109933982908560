import React from 'react';
import { Box, Button, Chip, Tooltip } from '@mui/material';
import TransactionIcon from '@mui/icons-material/ReceiptRounded';
import { useSnackbar } from 'notistack';


// region Props

interface Props {
  hash?: string | null;
  explorerUrl?: string | null;
}

// endregion


export const HashTile: React.FC<Props> = ({
  hash,
  explorerUrl
}) => {
  const snackbar = useSnackbar();

  const onCopy = async (e: any) => {
    e.preventDefault();

    if (hash) {
      try {
        await navigator.clipboard.writeText(hash);

        snackbar.enqueueSnackbar('Successfully copied hash');
      } catch (e) {
        snackbar.enqueueSnackbar(`Cannot copy hash. Copy manually: ${hash}`, {
          variant: 'error',
          action: () => (
            <Button
              color="error"
              onClick={onCopy}
            >
              Copy
            </Button>
          )
        });
      }
    }
  };

  const onOpenExplorer = () => {
    if (explorerUrl) {
      window.open(explorerUrl, '_blank');
    }
  };

  return (
    <Box
      onClick={
        explorerUrl
          ? onOpenExplorer
          : onCopy
      }
      onContextMenu={onCopy}
      sx={{
        maxWidth: '100%'
      }}
    >
      <Tooltip
        placement="top"
        title={
          hash
            ? explorerUrl
              ? 'Click to see in explorer. Right click to copy hash'
              : 'Click to copy'
            : ''
        }
      >
        <Chip
          size="small"
          color="warning"
          label={hash || 'Hash not available'}
          icon={<TransactionIcon />}
          sx={{
            flex: 1,
            borderRadius: '3px',
            fontWeight: '500'
          }}
        />
      </Tooltip>
    </Box>
  );
};
