import { Alert, Box, Typography } from '@mui/material';
import { DomainRestricted, Logo } from '@backed-fi/primitives';
import { ActionTile, NavigationTile } from '@backed-fi/compound';
import Dashboard from '@mui/icons-material/DashboardRounded';
import KanbanIcon from '@mui/icons-material/ViewKanbanRounded';
import Users from '@mui/icons-material/PeopleAltRounded';
import { AdminPermission } from '@backed-fi/graphql';
import Interactions from '@mui/icons-material/CurrencyExchangeRounded';
import Blockchain from '@mui/icons-material/CurrencyBitcoinRounded';
import FinancialsIcon from '@mui/icons-material/CandlestickChartRounded';
import EuroIcon from '@mui/icons-material/EuroRounded';
import BankIcon from '@mui/icons-material/AccountBalanceRounded';
import Internal from '@mui/icons-material/Dns';
import TradingEngine from '@mui/icons-material/DirectionsTransitFilledRounded';
import UtilsIcon from '@mui/icons-material/ConstructionRounded';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import LogoutIcon from '@mui/icons-material/Logout';
import React from 'react';
import { useAuthContext } from '@backed-fi/context';
import { EnvironmentLabel } from '@backed-fi/admin/src/app/components/EnvironmentLabel';

// region Props

interface Props {
  inDrawer?: boolean;
  onNavigate?: () => any;
}

// endregion

export const AdminNavigation: React.FC<Props> = ({ inDrawer, onNavigate }) => {
  // ---- Hooks ---- //
  const authContext = useAuthContext();

  const onLogout = async () => {
    await authContext.logout();
  };

  return (
    <Box
      sx={{
        width: inDrawer ? '100%' : '20%',
        maxWidth: '300px',
        maxHeight: '100vh',

        display: 'flex',
        flexFlow: 'column',

        borderRight: ({ palette }) =>
          `1px solid ${palette.utility.borderElement}`
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          margin: '2rem 0'
        }}
      >
        <Logo full />
      </Box>

      <Box
        sx={{
          flex: 1,
          width: '100%',
          padding: '1rem',
          display: 'flex',
          flexFlow: 'column',
          maxHeight: '100%',
          overflowY: 'scroll'
        }}
      >
        {!inDrawer && (
          <Typography ml=".5rem" variant="small">
            Navigation
          </Typography>
        )}

        <NavigationTile
          onNavigate={onNavigate}
          Icon={Dashboard}
          path="/"
          title="Dashboard"
        />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={Users}
          path="/clients"
          title="Clients"
          sections={[
            {
              title: 'Onboarding',
              path: '/list/onboarding',
              permission: AdminPermission.ClientRead
            },
            {
              title: 'Clients',
              path: '/list/onboarded',
              permission: AdminPermission.ClientRead
            },
            {
              title: 'Users',
              path: '/users',
              permission: AdminPermission.ClientRead
            },
            {
              title: 'Invitations',
              path: '/invitations/all',
              permission: AdminPermission.ClientRead
            }
          ]}
        />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={Interactions}
          path="/interactions"
          title="Interactions"
          sections={[
            {
              title: 'Interactions',
              path: '/all',
              permission: AdminPermission.InteractionRead
            },
            {
              title: 'Monitoring',
              path: '/monitoring',
              permission: AdminPermission.InteractionRead
            },
            {
              title: 'Refunds',
              path: '/refunds',
              permission: AdminPermission.InteractionRead
            }
          ]}
        />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={Blockchain}
          path="/blockchain"
          title="Blockchain"
          sections={[
            {
              title: 'Incoming Transactions',
              path: '/transactions/incoming/all'
            },
            {
              title: 'Internal Transactions',
              path: '/transactions/internal/all'
            },
            {
              title: 'Outgoing Transactions',
              path: '/transactions/outgoing/all'
            },
            {
              title: 'Known Addresses',
              path: '/addresses'
            },
            {
              title: 'Supply Operations',
              path: '/supply/operations'
            }
          ]}
        />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={FinancialsIcon}
          path="/financials"
          title="Brokerage"
          sections={[
            {
              title: 'Individual Orders',
              path: '/brokerage/orders',
              permission: AdminPermission.FinancialsRead
            },
            {
              title: 'Delivery Versus Payment ',
              path: '/brokerage/settlements',
              permission: AdminPermission.FinancialsRead
            }
          ]}
        />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={EuroIcon}
          path="/finops"
          title="FinOps"
          sections={[
            {
              title: 'Working Capital',
              path: '/capital',
              permission: AdminPermission.FinancialsRead
            },
            {
              title: 'Accounts',
              path: '/accounts/list',
              permission: AdminPermission.FinancialsRead
            },
            {
              title: 'Collateralisation',
              path: '/collateralisation',
              permission: AdminPermission.FinancialsRead
            }
          ]}
        />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={BankIcon}
          path="/banking"
          title="Banking"
          sections={[
            {
              title: 'Bank Transfers',
              path: '/transfers',
              permission: AdminPermission.FinancialsRead
            }
          ]}
        />
        <NavigationTile
          onNavigate={onNavigate}
          Icon={Internal}
          path="/internal"
          title="Internal Configurations"
          sections={[
            {
              title: 'Tokens',
              path: '/tokens/all',
              permission: AdminPermission.BlockchainRead
            },
            {
              title: 'Factories',
              path: '/factories/all',
              permission: AdminPermission.BlockchainRead
            },
            {
              title: 'Admins',
              path: '/admins/all',
              permission: AdminPermission.SystemManage
            },
            {
              title: 'Product Fees',
              path: '/fees/overview',
              permission: AdminPermission.FinancialsManage
            },
            {
              title: 'System wallets',
              path: '/wallets/all',
              permission: AdminPermission.BlockchainRead
            },
            {
              title: 'Roles',
              path: '/roles',
              permission: AdminPermission.SystemManage
            },
            {
              title: 'Events',
              path: '/events',
              permission: AdminPermission.SystemManage
            }
          ]}
        />
        <NavigationTile
          onNavigate={onNavigate}
          Icon={TradingEngine}
          path="/trading-engine"
          title="Trading Engine"
          permission={AdminPermission.DeveloperManage}
          sections={[
            {
              title: 'Trades',
              path: '/trades/list',
              permission: AdminPermission.SystemManage
            },
            {
              title: 'Execute Trade',
              path: '/trades/execute',
              permission: AdminPermission.SystemManage
            }
          ]}
        />

        {/*<NavigationTile
          onNavigate={onNavigate}
       */}
        {/*  Icon={Settings}*/}
        {/*  path="/settings"*/}
        {/*  title="System Settings"*/}
        {/*  sections={[{*/}
        {/*    title: 'Roles',*/}
        {/*    path: '/roles',*/}
        {/*    showWhenAny: [*/}
        {/*      AdminPermission.RoleManage,*/}
        {/*      AdminPermission.RoleRead*/}
        {/*    ]*/}
        {/*  }, {*/}
        {/*    title: 'Events',*/}
        {/*    path: '/events',*/}
        {/*    showWhenAny: [*/}
        {/*      AdminPermission.EventRead*/}
        {/*    ]*/}
        {/*  }]}*/}
        {/*/>*/}

        <Box flex={1} />

        <NavigationTile
          onNavigate={onNavigate}
          Icon={UtilsIcon}
          title="Utils"
          path="/utils"
        />

        <DomainRestricted dev local stage sandbox>
          <NavigationTile
            onNavigate={onNavigate}
            Icon={SwitchAccessShortcutIcon}
            title="Simulate"
            path="/simulate"
          />
        </DomainRestricted>

        <Box
          sx={{
            my: '.5rem'
          }}
        >
          <EnvironmentLabel />

          <ActionTile Icon={LogoutIcon} title="Log out" onClick={onLogout} />

          {/* <UserCard /> */}
        </Box>

        <Box>
          <Typography
            variant="small"
            sx={{
              color: 'utility.textLowContrast',
              fontWeigh: '300 !important'
            }}
          >
            &copy; Backed Finance. All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
