import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { config } from '@backed-fi/config';
import { SharedPages } from '@backed-fi/shared';

import { TestPage } from './Test';
import { AssessmentsListPage } from './domain/Compliance/Assessments/AssessmentsListPage';
import { FileRiskAssessmentPage } from './domain/Compliance/Assessments/FileRiskAssessmentPage';
import { AdminLayout } from './layouts/AdminLayout';
import { HomePage } from './domain/Home/HomePage';

import { TokensListPage } from './domain/Internal/Tokens/pages/TokenListPage';
import { CreateTokenPage } from './domain/Internal/Tokens/pages/CreateTokenPage';
import { TokenDetailsLayout } from './domain/Internal/Tokens/pages/Details/TokenDetailsLayout';
import { TokenDetailsOverviewPage } from './domain/Internal/Tokens/pages/Details/pages/OverviewPage';
import { TokenDeploymentsPage } from './domain/Internal/Tokens/pages/Details/pages/NetworksPage';
import { SystemWalletsListPage } from './domain/Internal/SystemWallets/SystemWalletsListPage';
import { DeployTokenPage } from './domain/Internal/Tokens/pages/Deployment/DeployTokenPage';
import { TokenOperationsPage } from './domain/Internal/Tokens/pages/Details/pages/OperationsPage';
import { TokenSettingsPage } from './domain/Internal/Tokens/pages/Details/pages/SettingsPage';
import { TokenReconciliationPage } from './domain/Internal/Tokens/pages/Details/pages/ReconciliationPage';
import { VerificationProfileDetailsPage } from './domain/Compliance/VerificationProfiles/VerificationProfileDetailsPage';
import { FactoriesListPage } from './domain/Internal/Factories/FactoriesListPage';
import { ImportTokenPage } from './domain/Internal/Tokens/pages/Deployment/ImportTokenPage';
import { RiskAssessmentDetailsPage } from './domain/Compliance/Assessments/RiskAssessmentDetailsPage';

import { UserPages } from './domain/User';
import { ClientPages } from './domain/Clients';
import { UtilsRoutes } from './domain/Utils/UtilsRoutes';
import { InteractionRoutes } from './domain/Interactions/routes';
import { BrokeragePages } from './domain/Brokerage/pages/Brokerage/BrokeragePages';
import { DocumentRoutes } from './domain/Compliance/Documents/Routes';
import { FinancialsRoutes } from './domain/Brokerage/FinancialsRoutes';
import { BlockchainRoutes } from './domain/Blockchain/BlockchainRoutes';
import { VerificationPages } from './domain/Compliance/Verifications/pages';
import { ComplianceReviewsRoutes } from './domain/Compliance/Reviews/Routes';
import { InvitationPages } from './domain/Invitations/InvitationPages';
import { BankingPages } from './domain/Banking/pages';
import { TradingEngineRoutes } from './domain/TradingEngine';
import { InternalPages } from '@backed-fi/admin/src/app/domain/Internal/InternalPages';
import { TokenOraclesPage } from '@backed-fi/admin/src/app/domain/Internal/Tokens/pages/Details/pages/OraclesPage';
import { CustodyRoutes } from './domain/FinOps';
import { TokenCollateralCustodyPage } from './domain/Internal/Tokens/pages/Details/pages/CollateralCustodyPage';
import { TokenDeploymentsListPage } from '@backed-fi/admin/src/app/domain/Internal/Tokens/pages/Details/pages/DeploymentsPage';
import { ExperimentalSettings } from '@backed-fi/admin/src/app/domain/Experimental/ExperimentalSettings';
import { Shortcuts } from '@backed-fi/admin/src/app/components/Shortcuts';
import { TokenStatisticsPage } from './domain/Internal/Tokens/pages/Details/pages/StatisticsPage';
import { TokenCollateralizationPage } from './domain/Internal/Tokens/pages/Details/pages/CollateralizationPage';

export const AdminPages = (
  <Route
    element={<Shortcuts />}
  >

    {SharedPages}

    {/* // ---- Dashboard domain ---- // */}
    <Route element={<AdminLayout />}>
      {UserPages}
      {UtilsRoutes}
      {ClientPages}
      {BankingPages}
      {InternalPages}
      {BrokeragePages}
      {DocumentRoutes}
      {InvitationPages}
      {BlockchainRoutes}
      {FinancialsRoutes}
      {InteractionRoutes}
      {ComplianceReviewsRoutes}
      {TradingEngineRoutes}
      {CustodyRoutes}

      <Route
        path="/"
        element={
          <HomePage />
        }
      />

      <Route
        path="/experimental"
        element={
          <ExperimentalSettings />
        }
      />


      {/* // ---- Verification domain ---- // */}
      <Route path="compliance">
        <Route path="risk">
          <Route
            path="assessments"
            element={
              <AssessmentsListPage />
            }
          />

          <Route path="assessment">
            <Route
              path="file/:assessmentId"
              element={
                <FileRiskAssessmentPage />
              }
            />

            <Route
              path="details/:id"
              element={
                <RiskAssessmentDetailsPage />
              }
            />
          </Route>
        </Route>

        {VerificationPages}

        <Route
          path="profiles"
        >
          <Route
            path="all"
          />
        </Route>

        <Route
          path="profile"
        >
          <Route
            path="details/:id"
            element={<VerificationProfileDetailsPage />}
          />
        </Route>
      </Route>

      {/* // ---- Internal external domain ---- // */}
      <Route path="internal">
        {/* // ---- Token domain ---- // */}
        <Route path="tokens">
          <Route
            path="all"
            element={
              <TokensListPage />
            }
          />

          <Route
            path="create"
            element={
              <CreateTokenPage />
            }
          />

          <Route
            path="deployments/import"
            element={
              <ImportTokenPage />
            }
          />

          {/* // ---- Settings & details domain  ---- // */}
          <Route
            path="details"
            element={
              <TokenDetailsLayout />
            }
          >
            <Route
              path=":id/overview"
              element={<TokenDetailsOverviewPage />}
            />
            <Route
              path=":id/statistics"
              element={<TokenStatisticsPage />}
            />
            <Route
              path=":id/multiplier"
              element={<TokenCollateralizationPage />}
            />

            <Route
              path=":id/deployments"
              element={<TokenDeploymentsListPage />}
            />

            <Route
              path=":id/operations"
              element={<TokenOperationsPage />}
            />


            <Route
              path=":id/oracles"
              element={<TokenOraclesPage />}
            />

            <Route
              path=":id/custody"
              element={<TokenCollateralCustodyPage />}
            />

            <Route
              path=":id/networks"
            >
              <Route
                index
                element={<TokenDeploymentsPage />}
              />


              <Route
                path="deploy"
                element={<DeployTokenPage />}
              />
            </Route>

            <Route
              path=":id/settings"
              element={<TokenSettingsPage />}
            />

            <Route
              path=":id/reconciliation"
              element={<TokenReconciliationPage />}
            />
          </Route>
        </Route>

        {/* // ---- System wallet domain ---- // */}
        <Route path="wallets">
          <Route
            path="all"
            element={<SystemWalletsListPage />}
          />
        </Route>

        {/* // ---- Factories ---- // */}
        <Route path="factories">
          <Route
            path="all"
            element={<FactoriesListPage />}
          />
        </Route>
      </Route>
    </Route>

    {(config.isDev) && (
      <Route
        path="/test"
        element={<TestPage />}
      />
    )}

    <Route
      path="*"
      element={
        <Navigate
          to="/"
        />
      }
    />
  </Route>
);
