import React from 'react';
import { Box, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { useClientFeesConfigurationDialogDataQuery, useCurrentClientFeesConfigurationLazyQuery, useUpsertClientFeesConfigMutation } from '@backed-fi/graphql';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import Decimal from 'decimal.js';

interface Props extends React.ComponentProps<typeof Dialog> {
  onFeesCustomised?: () => any;
}

// region Form Schema

const Schema = z.object({
  issuanceInitialFee: z.number()
    .nonnegative()
    .int(),

  issuanceMinimumFee: z.number()
    .nonnegative()
    .int(),

  issuancePercentageFee: z.number()
    .nonnegative(),

  redemptionInitialFee: z.number()
    .nonnegative()
    .int(),

  redemptionMinimumFee: z.number()
    .nonnegative()
    .int(),

  redemptionPercentageFee: z.number()
    .nonnegative()
});

// endregion

// region Graph

const Graph = gql`
  query clientFeesConfigurationDialogData {
    tokenDeployments {
      id
      network

      token {
        id
        name
      }
    }
  }

  query currentClientFeesConfiguration($clientId: String!, $tokenDeploymentId: String!) {
    feeConfiguration(
      clientId: $clientId,
      tokenDeploymentId: $tokenDeploymentId
    ) {
      id

      issuanceInitialFee
      issuanceMinimumFee
      issuancePercentageFee

      redemptionInitialFee
      redemptionMinimumFee
      redemptionPercentageFee
    }
  }

  mutation upsertClientFeesConfig($input: UpsertFeeConfigurationInput!) {
    upsertFeeConfiguration(input: $input) {
      id
    }
  }
`;

// endregion

const HelperTexts: Record<keyof typeof Schema.shape, string> = {
  issuanceInitialFee: 'The default is $0.00. It represents the upfront fee for every issuance',
  issuanceMinimumFee: 'The default is $0.00. It represents the minimum amount that will be charged for one issuance',
  issuancePercentageFee: 'The default is 0.5%. It represents the percentage charged of the total issuance amount',
  redemptionInitialFee: 'The default is $0.00. It represents the upfront fee for every redemption',
  redemptionMinimumFee: 'The default is $0.00. It represents the minimum amount that will be charged for one redemption',
  redemptionPercentageFee: 'The default is 0.5%. It represents the percentage charged of the total redemption amount'
};

export const ClientFeesConfigurationDialog: React.FC<Props> = ({ onFeesCustomised, ...props }) => {
  const params = useParams<{ id: string }>();
  const snackbar = useSnackbar();

  // region Networking

  const { data } = useClientFeesConfigurationDialogDataQuery();
  const [getCurrentProductFees, { loading: isFetchingCurrentFees }] = useCurrentClientFeesConfigurationLazyQuery();

  const [upsertClientFeesConfigMutation] = useUpsertClientFeesConfigMutation();

  // endregion

  // region State

  const [productId, setProductId] = React.useState<string>();
  const [tokenDeploymentId, setTokenDeploymentId] = React.useState<string>();

  // endregion

  // region Form Control

  const form = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema)
  });

  const { isSubmitting } = form.formState;

  // endregion

  // region Actions

  const onClose = () => {
    setProductId(undefined);
    setTokenDeploymentId(undefined);

    form.reset();

    if (typeof props.onClose === 'function') {
      (props.onClose as any)();
    }
  };

  const onTokenDeploymentSelected = async (tokenDeploymentId: string) => {
    try {
      form.reset();

      const { data } = await getCurrentProductFees({
        fetchPolicy: 'network-only',
        variables: {
          tokenDeploymentId,
          clientId: params.id!
        }
      });

      if (data?.feeConfiguration) {
        Object.keys(Schema.shape)
          .map((key: any) => {
            const value = (data.feeConfiguration as any)[key];

            form.setValue(key, key.includes('Percentage') ? value * 100 : value / 100);
          });
      }
    } catch {
    }

    setTokenDeploymentId(tokenDeploymentId);
  };

  const onSave = form.handleSubmit(async (data) => {
    await upsertClientFeesConfigMutation({
      variables: {
        input: {
          issuancePercentageFee: new Decimal(data.issuancePercentageFee).div(100).toNumber(),
          issuanceMinimumFee: new Decimal(data.issuanceMinimumFee).mul(100).toNumber(),
          issuanceInitialFee:  new Decimal(data.issuanceInitialFee).mul(100).toNumber(),

          redemptionPercentageFee: new Decimal(data.redemptionPercentageFee).div(100).toNumber(),
          redemptionMinimumFee: new Decimal(data.redemptionMinimumFee).mul(100).toNumber(),
          redemptionInitialFee: new Decimal(data.redemptionInitialFee).mul(100).toNumber(),

          clientId: params.id!,
          tokenDeploymentId: tokenDeploymentId!
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        'clientFeeOverrides'
      ]
    });

    onClose();

    snackbar.enqueueSnackbar('Successfully updated client fees', {
      variant: 'success'
    });
  });

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      {...props}
      onClose={onClose}
    >
      <DialogContent>
        <Box mb={2}>
          <Typography variant='titleSmall'>
            Client Fees
          </Typography>

          <Typography variant='subtitleSmall'>
            Customise fees per asset for specific client
          </Typography>
        </Box>


        {data && (
          <React.Fragment>
            <TextField
              select
              fullWidth
              size='small'
              label='Product'
              value={productId}
              onChange={(event) => {
                setProductId(event.target.value);
              }}
            >
              <option selected>Select Token</option>

              {Array.from(new Set(data.tokenDeployments.map(x => x.token))).map((value) => (
                <option key={value.id} value={value.id}>
                  {value.name}
                </option>
              ))}
            </TextField>

            {productId && (
              <TextField
                select
                fullWidth
                size='small'
                label='Product Network'
                onChange={(event) => {
                  onTokenDeploymentSelected(event.target.value);
                }}
              >
                <option selected>Select Network</option>

                {data.tokenDeployments.filter((x) => x.token.id === productId).map((value) => (
                  <option key={value.id} value={value.id}>
                    {value.network}
                  </option>
                ))}
              </TextField>
            )}

            {isFetchingCurrentFees && (
              <Box>
                Loading..
              </Box>
            )}

            {(tokenDeploymentId && !isFetchingCurrentFees) && (
              <Box>
                {Object.keys(Schema.shape)
                  .map((key: string) => (
                    <TextField
                      fullWidth
                      sx={{
                        my: 1.5,
                        '& > .MuiFormLabel-root': {
                          textTransform: 'capitalize'
                        }
                      }}
                      key={key}
                      label={key.replace(/(?=[A-Z])/g, ' ')}
                      helperText={HelperTexts[key as keyof typeof Schema.shape]}
                      {
                        ...form.register(key as any, {
                          valueAsNumber: true
                        })
                      }
                    />
                    )
                  )}

                <LoadingButton
                  onClick={onSave}
                  loading={isSubmitting}
                >
                  Save Fees
                </LoadingButton>
              </Box>
            )}
          </React.Fragment>
        )}


      </DialogContent>
    </Dialog>
  );
};
